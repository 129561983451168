import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface IcolumnsName {
	first_name: string;
	last_name: string;
	phone_number: string;
	email: string;
	mailing_address: string;
	id_number: string;
	hire_date: string;
	status: string;
}

export interface CreateEmployeeFromFileState {
	step: number;
	maxStep: number;
	loadedfileData: { [key: string]: string }[];
	// columnsName: IcolumnsName;
}

const initialState: CreateEmployeeFromFileState = {
	step: 0,
	maxStep: 3,
	loadedfileData: [],
};

export const createEmployeeFromFileSlice = createSlice({
	name: 'createEmployeeFromFileSlice',
	initialState,
	reducers: {
		setStep: (state, action: PayloadAction<number>) => {
			state.step = Math.min(Math.max(action.payload, 0), state.maxStep);
		},
		setNextStep: state => {
			state.step = Math.min(state.step + 1, state.maxStep);
		},
		setPrevStep: state => {
			state.step = Math.max(state.step - 1, 0);
		},
		setLoadedFileData: (
			state,
			action: PayloadAction<{ [key: string]: string }[]>,
		) => {
			state.loadedfileData = action.payload;
		},
		resetFormSate: state => initialState,
	},
});

export const {
	setStep,
	setLoadedFileData,
	resetFormSate,
	setNextStep,
	setPrevStep,
} = createEmployeeFromFileSlice.actions;

export const selectCreateEmployeeFromFileState = (state: RootState) =>
	state.createEmployeeFromFile;

export default createEmployeeFromFileSlice.reducer;
