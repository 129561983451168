import { IEmployeeDocumentReviewDataResponse } from 'src/interfaces';
import { fromBase64String } from 'src/utils';
import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllDocumentsService: (
	token: string,
	folderId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, folderId: string) => {
	return fetchService({
		url: `${Apis.DOCUMENTS}?folderId=${folderId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetDocumentService: (
	token: string,
	documentId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, documentId: string) => {
	return fetchService({
		url: `${Apis.DOCUMENT}?documentId=${documentId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetEmployeeDocumentReviewDataService: (
	userToken: string,
	base64Token: string,
) => Promise<{
	data: IEmployeeDocumentReviewDataResponse;
	status: number;
}> = async (userToken, base64Token: string) => {
	const queryParams = fromBase64String(base64Token);
	return fetchService({
		url: `${Apis.GET_EMPLOYEE_DOCUMENT_REVIEW_DATA}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: userToken,
		queryParams,
	});
};

const GetDocumentsOfLocationService: (
	locationId: number,
) => Promise<{
	data: any;
	status: number;
}> = async (locationId: number) => {
	return fetchService({
		url: Apis.DOCUMENTS + '?locationId=' + locationId,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
	});
};

const GetNumberOfDocumementsService: (
	locationId: string,
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (locationId: string, token: string) => {
	return fetchService({
		url: `${Apis.NUMBER_OF_DOCUMENTS}/${locationId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddDocumentService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_DOCUMENT,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const AddDocumentBulkService: (
	token: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: Apis.ADD_DOCUMENT_BULK,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const AddMultipleDocumentService: (
	token: string,
	data: FormData,
) => Promise<{ data: any; status: number }> = async (token, data) => {
	return fetchService({
		url: Apis.MULTIPLE_DOCUMENTS,
		method: 'POST',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
		formData: data,
	});
};

const UpdateDocumentService: (
	token: string,
	documentId: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (
	token,
	documentId,
	body,
) => {
	return fetchService({
		url: `${Apis.UPDATE_DOCUMENT}/${documentId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const UploadDocumentsService: (
	token: string,
	data: FormData,
) => Promise<{ data: any; status: number }> = async (token, data) => {
	return fetchService({
		url: Apis.UPLOAD_DOCUMENTS,
		method: 'POST',
		addContentTypeApplicationJson: false,
		token: token,
		formData: data,
	});
};

const DeleteDocumentService: (
	token: string,
	documentId: string,
) => Promise<{ data: any; status: number }> = async (token, documentId) => {
	return fetchService({
		url: `${Apis.DELETE_DOCUMENT}/${documentId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

const AddEmployeeDocumentService: (
	token: string,
	body: FormData,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: `${Apis.ADD_EMPLOYEE_DOCUMENT}/${token}`,
		method: 'POST',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		formData: body,
	});
};

export {
	AddDocumentService,
	AddEmployeeDocumentService,
	AddMultipleDocumentService,
	DeleteDocumentService,
	GetAllDocumentsService,
	GetDocumentService,
	GetDocumentsOfLocationService,
	GetEmployeeDocumentReviewDataService,
	GetNumberOfDocumementsService,
	UpdateDocumentService,
	AddDocumentBulkService,
	UploadDocumentsService,
};
